import React from 'react'
import { Box, Text, Container, Card as CardComponent } from 'theme-ui'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '../../../../flow-ui/flow-ui-components/src/CardList/CardList'
import Card from '@components/Card'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import AuthorExpanded from '@widgets/AuthorExpanded'
import NewsletterCompact from '@widgets/NewsletterCompact'
import TableOfContentsExpanded from '@widgets/TableOfContentsExpanded'
import { PostImage, PostBody, PostComments, PostTagsShare } from '@widgets/Post'
import { useHeader } from '@helpers-blog/useHeader'
import ModalWithTabs from '../../../../solid-ui/solid-ui-blocks/src/Modal/Block01'

import { normalizeBlockContentNodes } from '@blocks-helpers'
const styles = {
  card: {
    bg: '#E6F2FC',
    border: '1px solid #42B3FF',
    borderRadius: '8px',
    width: '114px',
    padding: '2px',
    color: '#42B3FF',
    marginBottom: '20px'
  }
}


const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props
  
  
  const { allBlockContent } = useHeader().props.children
  // const allBlockContent = props.children.allBlockContent
  
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo {...post} siteUrl={siteUrl} />
      {/* <ModalWithTabs content={content['contact']} /> */}
      <Divider />
      <Container>
        <Box sx={styles.card}>
          <Text style={{ textAlign: 'center' }}>Novedades</Text>
        </Box>
      </Container>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Main>
          <Card {...post} variant='horizontal-hero' omitExcerpt omitMedia />
        </Main>
        {services.mailchimp && (
          <Sidebar pl={[null, 2, 4, 5]}>
            <NewsletterCompact omitTitle />
          </Sidebar>
        )}
      </Stack>
      <Divider space={3} />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          {post.tableOfContents?.items && (
            <>
              <TableOfContentsExpanded {...post} />
              {/* <Divider /> */}
            </>
          )}
          {/* ///////////////  QUITO POR EL MOMENTO LA IMAGEN DEL POSTEO ////////////////////////*/}
          <CardComponent variant='paper-lg'>
            {/* <PostImage {...post} inCard /> */} 
            <PostBody {...post} />
            <PostTagsShare {...post} location={props.location} />
            {services.disqus && <PostComments {...post} />}

          </CardComponent>
          <Divider />
          {/* <AuthorExpanded author={post.author} /> */}
          <Divider />
          {/* ESTO AGREGA NOTICIAS RELACIONADAS */}
          {post.category && (
            <CardList
              title='Noticias Relacionadas'
              nodes={relatedPosts}
              variant={['horizontal-md']}
              columns={[1, 2, 2, 2]}
              limit={2}
              distinct
            />
          )}
        </Main>
      </Stack>
    </Layout>
  )
}

export default Post
