import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'

/**
 * Strapi uses a relative URL for images that are embedded in the articles, we
 * must replace that with the absolute URL to make them work.
 *
 * @type {string}
 */
const imageRelativeUrl = '"src": "/uploads/'
const imageAbsoluteUrl = `"src": "${process.env.GATSBY_IMAGE_URL_PREFIX}/uploads/`

export const PostBody = ({ body }) => {
  body = body.replace(imageRelativeUrl, imageAbsoluteUrl)
  return (
    <MDXProvider components={components}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  )
}
